<template>
    <v-card v-if="project" flat>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate color="white" :size="70"> </v-progress-circular>
        </v-overlay>

        <v-dialog v-model="displayFile" max-width="60%">
            <v-img contain height="100%" :src="`${fileToSee.url}?token=${token}`"></v-img>
        </v-dialog>

        <v-card-title v-if="withTitle" class="pt-0 subtitle-1">
            <v-icon left>mdi-paperclip</v-icon>
            <span v-text="$t('issues.menu.files')" />
        </v-card-title>
        <v-card-text v-if="issue.files.length > 0">
            <div class="d-flex flex-wrap">
                <v-chip
                    small
                    dark
                    close
                    outlined
                    color="light-green darken-1"
                    v-for="(file, index) in issue.files"
                    :key="index"
                    @click="showFile(file)"
                    @click:close="deleteFile(file)"
                    class="ma-2"
                >
                    {{ file.name }} -
                    {{ labelSize(file.size) }}
                </v-chip>
            </div>
        </v-card-text>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col class="py-0" cols="12" md="10">
                        <v-file-input
                            v-model="files"
                            counter
                            multiple
                            :placeholder="$t('issues.actions.add_files')"
                            filled
                            dense
                            prepend-icon=""
                            :show-size="1024"
                        >
                            <template v-slot:selection="{ text }">
                                <v-chip dark label small>
                                    {{ text }}
                                </v-chip>
                            </template>
                        </v-file-input>
                    </v-col>
                    <v-col class="py-0" cols="12" md="1">
                        <v-btn v-if="files.length > 0" color="primary" @click="uploadFiles">
                            {{ $t('issues.actions.upload_files') }}
                            <v-icon right dark>mdi-cloud-upload</v-icon></v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/utils/api';

export default {
    name: 'issue-files',
    data: () => ({
        files: [],
        loading: false,
        displayFile: false,
        fileToSee: {},
    }),
    props: {
        issue: {
            default: undefined,
        },
        withTitle: {
            default: false,
        },
        onlyEdit: {
            default: false,
        },
    },
    computed: {
        ...mapGetters(['project', 'token']),
    },
    methods: {
        ...mapActions(['addFilesToIssue', 'deleteFileToIssue', 'addFilesToNewIssue', 'deleteFileToNewIssue']),
        async uploadFiles() {
            this.loading = true;
            if (this.files.length > 0) {
                let formData = new FormData();
                for (let file of this.files) {
                    formData.append('files', file, file.name);
                }

                if (!this.onlyEdit) {
                    const issueToUpdate = {
                        id: this.issue.id,
                        data: formData,
                    };

                    await this.addFilesToIssue(issueToUpdate);
                } else {
                    const files = {
                        data: formData,
                    };
                    let filesToAdd = await this.addFilesToNewIssue(files);
                    this.$emit('update-attribute', { name: 'files', value: filesToAdd });
                }
                this.files = [];
            }
            this.loading = false;
        },
        async deleteFile(file) {
            if (!this.onlyEdit) {
                const issueToUpdate = {
                    id: this.issue.id,
                    file: file.id,
                };

                await this.deleteFileToIssue(issueToUpdate);
            } else {
                await this.deleteFileToNewIssue(file);
                let files = this.issue.files.filter((f) => f.url !== file.url);
                this.$emit('update-attribute', { name: 'update-files', value: files });
            }
        },
        showFile(file) {
            //Check if file is image
            const extension = file.name.toLowerCase().split('.').pop();
            if (['png', 'jpg', 'jpeg'].includes(extension)) {
                this.displayFile = true;
                this.fileToSee = file;
            } else if (['pdf'].includes(extension)) {
                window.open(`${file.url}?token=${this.token}`, '_blank');
            } else {
                const a = document.createElement('a');
                a.style.display = 'none';
                document.body.appendChild(a);
                a.href = `${file.url}?token=${this.token}`;
                a.setAttribute('download', file.filename);
                a.click();
                document.body.removeChild(a);
            }
        },
        labelSize(bytes) {
            const sizes = ['Bits', 'Ko', 'Mo', 'Go', 'To'];
            if (bytes == 0) return '0 Bit';
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
    },
};
</script>
