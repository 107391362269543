<template>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
        <v-row v-if="issue && project">
            <v-col cols="12" md="9">
                <v-card>
                    <v-card-title>
                        <issue-name
                            v-model="issue.name"
                            :issue="issue"
                            :display-like="false"
                            :display-watch="false"
                            :only-edit="true"
                            :display-number="false"
                        />
                    </v-card-title>

                    <v-card-text>
                        <issue-description
                            v-model="issue.description"
                            :issue="issue"
                            :allow-editing="true"
                            :only-edit="true"
                        />
                    </v-card-text>
                    <v-divider />
                    <v-card-text>
                        <issue-files
                            ref="issueFiles"
                            :issue="issue"
                            :with-title="true"
                            :only-edit="true"
                            v-on:update-attribute="updateAttribute"
                        />
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="cancelCreate()" v-text="$t('buttons.cancel')" />
                        <v-btn color="success" text @click="save()" v-text="$t('buttons.save')" :disabled="invalid" />
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-text>
                        <issue-attributes
                            :issue.sync="issue"
                            :only-edit="true"
                            v-on:update-attribute="updateAttribute"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </ValidationObserver>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { projectMemberMixin } from '@/mixins/member';
import issueAttributes from './attributes.vue';
import issueFiles from './files.vue';
import issueName from '@/components/issues/name';
import issueDescription from '@/components/issues/description';
import Clone from 'lodash/clone';

export default {
    name: 'project-issue-create',
    mixins: [projectMemberMixin],
    data: () => ({
        issue: undefined,
    }),
    components: {
        issueName,
        issueDescription,
        issueAttributes,
        issueFiles,
    },
    mounted() {
        if (this.project) {
            this.emptyIssue();
        }
    },
    watch: {
        project: function (value) {
            if (value) {
                this.emptyIssue();
            }
        },
    },
    computed: {
        ...mapGetters(['profile', 'project', 'trackers', 'status', 'severities', 'priorities']),
        trackersAvailables() {
            if (this.project) {
                return this.trackers.filter((t) => t.roles.some((r) => this.profileRoles.includes(r)));
            }
            return [];
        },
        profileRoles() {
            if (this.project) {
                return this.project.members.find((m) => m.user === this.profile.name).roles;
            }
            return [];
        },
    },
    methods: {
        ...mapActions(['createIssue', 'deleteFileToNewIssue']),
        emptyIssue() {
            this.issue = {
                project: this.project.name,
                name: '',
                description: '',
                done: 0,
                tracker: this.trackersAvailables[0].name,
                status: this.status.find((s) => s.default).name,
                severity: this.severities.find((s) => s.default).value,
                priority: this.priorities.find((s) => s.default).value,
                files: [],
                parent: this.$route.query.parent ? this.$route.query.parent : undefined,
                module: undefined,
                category: undefined,
            };
        },
        updateAttribute({ name, value }) {
            if (name === 'files') {
                this.issue.files = this.issue.files.concat(value);
            }
            if (name === 'update-files') {
                this.issue.files = value;
            } else {
                this.issue[name] = value;
            }
        },
        cancelCreate() {
            if (this.issue.files.length > 0) {
                this.issue.files.forEach(async (f) => {
                    await this.deleteFileToNewIssue(f);
                });
            }
            this.emptyIssue();

            this.$router.go(-1);
        },
        async save() {
            // Send files before creating
            await this.$refs.issueFiles.uploadFiles();

            let issue = Clone(this.issue);
            await this.createIssue(issue);
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
.v-tabs--vertical > .v-tabs-bar .v-tab {
    height: 36px;
}
.v-divider {
    max-width: 80%;
    margin: 0 auto;
}
</style>
